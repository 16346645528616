import * as React from 'react'
import Field, { BaseFormFieldProps } from '../index'
import Select from 'react-select'
import classNames from 'classnames'

export interface SelectOptions {
  value?: string
  label: string
  [key: string]: any
}

export interface ReactSelectProps extends BaseFormFieldProps {
  options: SelectOptions[]
  multiple?: boolean
}

export const InputField: React.FunctionComponent<ReactSelectProps> = (props) => {
  const defaultValue = React.useMemo(() => {
    if (!props.defaultValue && props.multiple)
      return []
    else if (!props.defaultValue)
      return {}
    if (Array.isArray(props.defaultValue))
      return props.defaultValue.map((value: string) => 
        props.options.find(o => o.value === value) 
      )
    return props.options.find(o => o.label === props.defaultValue)
  }, [props.defaultValue, props.options])
  
  return(
    <Field { ...props }>
      <Select
        defaultValue={ defaultValue || [] }
        isMulti={ props.multiple }
        name={ props.name }
        options={ props.options }
        closeMenuOnSelect={ false }
        onChange={ (selectedOption) => {
          if (!props.onChange)
            return
          if (Array.isArray(selectedOption))
            return props.onChange(selectedOption && selectedOption.map((o: any) => o.value))
          return props.onChange(selectedOption)
        } }
        classNamePrefix='c-field-react-select'
        //className= {
        /*classNames('c-field-react-select', {
            'is-valid': !props.hideValid && !Boolean(props.error),
            'is-invalid': Boolean(props.error)
          })*/
        //}
      />
    </Field>
  )
}
export default InputField