import CreateUser from './containers/users/create'
import Users from './containers/users/list'
import User from './containers/users/item'
import Login from '_containers/login'
import Logout from '_containers/logout'
import PasswordRecovery from '_containers/password-recovery'
import PasswordReset from '_containers/password-reset'
import IsAuth from './containers/is-auth'
import UserProfile from '_containers/user-profile'
import MediasList from '_containers/medias/list'
import ArticlesList from '_containers/articles/list'
import ArticleItem from '_containers/articles/item'
import ReportsList from '_containers/reports/list'
import ReportItem from '_containers/reports/item'
import MembranesList from '_containers/membranes/list'
import MembraneItem from '_containers/membranes/item'

interface Route {
  path: string
  component: any
  logged?: boolean | 'common'
}

const routes: Route[] = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/logout',
    component: Logout,
    logged: true
  },
  {
    path: '/password-recovery',
    component: PasswordRecovery
  },
  {
    path: '/reset-password',
    component: PasswordReset
  },
  {
    path: '/news',
    component: ArticlesList,
    logged: true
  },
  {
    path: '/news/:id',
    component: ArticleItem,
    logged: true
  },
  {
    path: '/releases',
    component: ArticlesList,
    logged: true
  },
  {
    path: '/releases/:id',
    component: ArticleItem,
    logged: true
  },
  {
    path: '/reports',
    component: ReportsList,
    logged: true
  },
  {
    path: '/reports/:id',
    component: ReportItem,
    logged: true
  },
  {
    path: '/membranes',
    component: MembranesList,
    logged: true
  },
  {
    path: '/membranes/:id',
    component: MembraneItem,
    logged: true
  },
  {
    path: '/medias',
    component: MediasList,
    logged: true
  },
  {
    path: '/users',
    component: Users,
    logged: true
  },
  {
    path: '/users/create',
    component: CreateUser
  },
  {
    path: '/users/:id',
    component: User,
    logged: true
  },
  {
    path: '/is-auth',
    component: IsAuth,
    logged: 'common'
  },
  {
    path: '/me',
    component: UserProfile,
    logged: true
  }
]

export const getRoute = (path: string): Route | undefined =>
  routes.find(r => r.path === path)

export default routes