import * as React from 'react'
import { Formik, FormikProps, Field, Form as FormikForm } from 'formik'
import RequestMessage from '_components/request-message'
import { Button, Col, Row } from 'react-bootstrap'
import { FormFieldProp } from './types'
import { getComponent } from './utils'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons'
import Title from '_components/title'
import classNames from 'classnames'
import { Prompt } from 'react-router'

export type FieldsProps = FormFieldProp[]

interface CancelButtonProp {
  onClick(): void
  label?: string
}

export interface FormProps {
  fields: FieldsProps
  initialValues?: any
  request?: ApiRequest
  validate?(value: any): any
  onSubmit(values: any): any
  onFieldChange?(fieldName: string, value: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void): void
  success?(): any
  submitLabel?: string
  submitLg?: boolean
  cancelButton?: CancelButtonProp
  buttonPosition?: 'bottom' | 'top' | 'all'
  noButton?: boolean
  unsavedAlert?: boolean
  forceShowSubmit?: boolean
  noSubmitOnEnter?: boolean
}

interface ButtonsProps {
  submitLg?: boolean
  submitLabel?: string
  request?: ApiRequest
  cancelButton?: CancelButtonProp
  disabled?: boolean
  className?: string
}

const Buttons: React.FunctionComponent<ButtonsProps> = ({
  submitLg,
  submitLabel,
  request,
  cancelButton,
  disabled,
  className
}) =>
  <>
    <Button
      className={ classNames(className) }
      variant='primary'
      block={ submitLg }
      type='submit'
      disabled={ disabled }
    >
      { submitLabel || 'Submit' }
      {
        request && request.status === 'inProgress' && <FontAwesomeIcon className='ml-1' icon={ faSpinner } spin/>
      }
      {
        request && request.status === 'success' && <FontAwesomeIcon className='ml-1' icon={ faCheck } />
      }
    </Button>
    {
      cancelButton &&
      <Button
        className='mt-4 ml-2'
        variant='default'
        block={ submitLg }
        type='button'
        onClick={ cancelButton.onClick }
      >
        { cancelButton.label || 'Cancel' }
      </Button>
    }
  </>

interface FormFieldProps {
  field: FormFieldProp
  onFieldChange?(fieldName: string, value: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void): void
  values: any
  errors: any
  touched: any
  submitCount: number
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  initialValues: any
}

const FormField: React.FunctionComponent<FormFieldProps> = ({
  field,
  onFieldChange,
  values,
  errors,
  touched,
  submitCount,
  setFieldValue
}) => (
  <Field
    key={ field.name }
    { ...field }
    component={ getComponent(field.type) }
    className={ field.className }
    placeholder={ field.placeholder }
    submitCount={ submitCount }
    error={ _.get(errors, field.name) }
    defaultValue={ _.get(values, field.name) }
    onChange={ (value: any) => {
      onFieldChange && onFieldChange(field.name, value, setFieldValue)
      setFieldValue(field.name, value)
    } }
    setFieldValue={ setFieldValue }
    touched={ Boolean(submitCount || _.get(touched, field.name)) }
    values={ values }
  />
)

const Form: React.FunctionComponent<FormProps> = ({ 
  fields, 
  initialValues, 
  validate, 
  onSubmit, 
  request, 
  success,
  submitLabel,
  submitLg,
  cancelButton,
  onFieldChange,
  buttonPosition,
  noButton,
  unsavedAlert,
  forceShowSubmit,
  noSubmitOnEnter
}) => {
  if (request && request.status === 'success' && success)
    return success()
  
  return (
    <Formik
      enableReinitialize
      initialValues={ initialValues || {} }
      validate={ validate }
      onSubmit={ onSubmit }
      on
    >
      {({
        values = {},
        errors = {},
        touched = {},
        submitCount,
        setFieldValue
      }: FormikProps<any>) => {
        const isModified = Boolean(!initialValues || !values || !_.isEqual(values, initialValues))
        return (
          <FormikForm
            onKeyDown= { noSubmitOnEnter ? (keyEvent) =>
              keyEvent.key === 'Enter' && keyEvent.preventDefault()
              : undefined
            }
          >
            <RequestMessage
              request={ request }
            />
            {
              unsavedAlert && 
                <Prompt
                  when={ isModified }
                  message='You have unsaved changes, are you sure you want to leave?'
                />
            }
            {
              (buttonPosition === 'top' || buttonPosition === 'all') && (isModified || forceShowSubmit) &&
              <div className='mb-2 text-right top-buttons'>
                <Buttons 
                  submitLg={ submitLg }
                  submitLabel={ submitLabel }
                  request={ request }
                  cancelButton={ cancelButton }
                  //disabled={ !isModified }
                />
              </div>
            }
            {
              fields.map((field, index) => {
                if (field.type === 'form') {
                  return (
                    <div key={ index }>
                      <field.form />
                    </div>
                  )
                } else if (field.type === 'section') {
                  return (
                    <Row key={ index }>
                      {
                        field.section && field.section.title &&
                      <Col md='12'>
                        <Title
                          label={ field.section.title }
                          level={ 4 }
                          className='mt-4 mb-1'
                        />
                      </Col>
                      }
                      {
                        field.section && field.section.fields.map((field, index) => (
                          <Col { ...field.colProps } key={ index }>
                            <FormField
                              field={ field.field }
                              initialValues={ initialValues }
                              onFieldChange={ onFieldChange }
                              values={ values }
                              errors={ errors }
                              touched={ touched }
                              submitCount={ submitCount }
                              setFieldValue={ setFieldValue }
                            />
                          </Col>))
                      }
                    </Row>
                  )
                }
                return (
                  <FormField
                    field={ field }
                    onFieldChange={ onFieldChange }
                    values={ values }
                    errors={ errors }
                    touched={ touched }
                    submitCount={ submitCount }
                    setFieldValue={ setFieldValue }
                    key={ index }
                    initialValues={ initialValues }
                  />
                )
              
              })
            }
            {
              (!buttonPosition || buttonPosition === 'bottom' || buttonPosition === 'all') && !noButton &&
              <Buttons 
                submitLg={ submitLg }
                submitLabel={ submitLabel }
                request={ request }
                cancelButton={ cancelButton }
                className='mt-4'
                //disabled={ !isModified }
              />
            }
          </FormikForm>
        )
      }}
    </Formik>
  )
}

export default Form
