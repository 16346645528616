import * as React from 'react'

export interface UserNameProps {
  user?: {
    firstName: string
    lastName: string
    [key: string]: any
  }
  mode?: 'initial' | 'short' | 'full'
  after?: string
  before?: string
}

const UserName: React.FunctionComponent<UserNameProps> = ({
  user,
  mode,
  after,
  before
}) => {
  const string = React.useMemo(() => {
    let string
    if (!user || (!user.firstName && !user.lastName))
      return string
    if (mode === 'initial')
      string = `${user.firstName.toUpperCase()}${user.lastName.toUpperCase()}`
    else if (mode === 'short')
      string = `${user.firstName ? user.firstName[0].toUpperCase() : ''}${user.firstName ? '. ' : ''}${user.lastName}`
    else
      string = `${user.firstName} ${user.lastName}`

    if (before)
      string = `${before}${string}`
    if (after)
      string = `${string}${after}`

    return string
  }, [user, mode])

  return <span>{ string }</span>
}

export default UserName