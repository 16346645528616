import { faCheck, faExclamationCircle, faWrench, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { Variant } from 'react-bootstrap/esm/types'
import { IssueStatus, Status } from './types'

export const getStatusVariant =
  (status: Status | IssueStatus | string): Variant => {
    switch (status) {
      case Status.accepted:
      case IssueStatus.closed:
        return 'success'
      case IssueStatus.inProgress:
      case Status.detailsRequired:
        return 'info' 
      case Status.toValidate:
      case IssueStatus.open:
        return 'warning' 
      case Status.rejected:
        return 'danger' 
      case IssueStatus.open:
        return 'danger' 
      default:
        return 'primary'
    }


  }

export const getIssueStatusIcon =
  (status: IssueStatus | string): IconDefinition => {
    switch (status) {
      case 'In progress':
        return faWrench
      case 'Closed':
        return faCheck
      default:
        return faExclamationCircle
    }
  }