import * as React from 'react'
import { Modal } from 'react-bootstrap'
import InviteUserForm from './invite-form'

interface InviteUserModalProps {
  isOpen: boolean
  setOpen(isOpen?: boolean): void
}

const InviteUserModal: React.FunctionComponent<InviteUserModalProps> = ({
  isOpen,
  setOpen
}) => (
  <Modal
    show={ isOpen }
    onHide={ () => setOpen(false) }
  >
    <Modal.Header>
        Invite new user
    </Modal.Header>
    <Modal.Body>
      <p>
        Send an invitation to the user of your choice, s.he will receive an email with a link that will allow Â him.her to complete and validate the account.
      </p>
      <div className='mb-3'>
        <InviteUserForm />
      </div>
    </Modal.Body>
  </Modal>
)

export default InviteUserModal