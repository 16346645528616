import * as React from 'react'
import Title from '_components/title'
import { useCurrentReport, useReports } from '_stores/reports/store'
import ReportForm from './form'
import { Report } from '_stores/reports/types'
import { useHistory } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import ApiItemWrapper from '_containers/api-item-wrapper'
import ConfirmationModal from '_components/confirmation-modal'
import RequestMessage from '_components/request-message'

export interface ReportItemProps {
  report: Report
}

const ReportItem: React.FunctionComponent = () => {
  const [state] = useReports()
  const [currentReport, actions] = useCurrentReport()
  const history = useHistory()
  
  //Delete confirmation modal
  const [promptIsOpen, displayPrompt] = React.useState(false)

  const deleteRequest = currentReport && currentReport._id && state.requests[`delete /reports/${currentReport._id}`]

  if (deleteRequest && deleteRequest.status === 'success')
    return (
      <PageApp>
        <ConfirmationModal
          isOpen={ true }
          confirm={ () => {
            history.push('/reports')
          } }
          title={ 'Item deleted' }
          description={ 
            <p>
              The report is deleted.
            </p>
          }
        />
      </PageApp>
    )
  
  return (
    <PageApp>
      <ApiItemWrapper
        item={ currentReport }
        actions={ actions }
      >
        <ConfirmationModal
          isOpen={ promptIsOpen }
          confirm={ () => {
            displayPrompt(false)
            currentReport && currentReport._id && actions.delete(currentReport._id)
          } }
          cancel={ () => displayPrompt(false) }
          title={ `Delete ${currentReport?.reference}` }
          description={ 
            <p>
              Are you sure you want to delete&nbsp;
              <strong>{ currentReport?.reference }</strong> ?<br /><br />
              This action is <strong className='text-danger'>irreversible</strong>. The report will not be deleted if at least one membrane uses it.
            </p>
          }
        />
        <Title
          label={ currentReport ? currentReport.reference : 'New report' }
          buttons={ [
            {
              label: 'Delete',
              variant: 'danger',
              onClick: () => displayPrompt(true)
            }, 
            {
              label: 'Back to list',
              onClick: () => history.push('/reports')
            }] }
        />
        {
          currentReport && currentReport._id &&
          <RequestMessage
            request={ deleteRequest }
          />
        }
        <ReportForm />
        {
          currentReport && currentReport.link &&
            <div className='text-right'>
              <a href={ currentReport.link } target='__blank' >{ currentReport.link }</a>
            </div>
        }
      </ApiItemWrapper>
    </PageApp>
  )
}

export default ReportItem