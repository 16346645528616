import * as React from 'react'
import { Button, Modal } from 'react-bootstrap'

interface ConfirmationModalProp {
  isOpen: boolean
  title: string
  description: string | React.ReactElement
  confirm(): void
  cancel?(): void
  confirmLabel?: string
  cancelLabel?: string
}

const ConfirmationModal: React.FunctionComponent<ConfirmationModalProp> = ({
  isOpen,
  cancel,
  title,
  description,
  confirm,
  confirmLabel,
  cancelLabel
}) => (
  <Modal
    show={ isOpen }
    onHide={ cancel }
  >
    <Modal.Header>
      { title }
    </Modal.Header>
    <Modal.Body>
      { description }
    </Modal.Body>
    <Modal.Footer>
      <>
        <Button
          variant='default'
          onClick={ cancel }
        >
          { cancelLabel || 'Cancel' }
        </Button>
        <Button
          variant='primary'
          onClick={ confirm }
        >
          { confirmLabel || 'Ok' }
        </Button>
      </>
    </Modal.Footer>
  </Modal>

)

export default ConfirmationModal