import * as React from 'react'
import Form, { FieldsProps } from '_components/form'
import { useUsers } from '_stores/users/store'
import { CompleteProfilePayload } from '_stores/users/types'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import jwtDecode from 'jwt-decode'
import countries from '_constants/countries'

const UserForm: React.FunctionComponent = () => {
  const [state, actions] = useUsers()
  const location = useLocation()
  const query = queryString.parse(location.search)
  const token = query.token as string | undefined
  const decodedToken: any = token && jwtDecode(token)

  if (!token || !decodedToken) {
    return (
      <p className='text-error'>Invalid link</p>
    )
  }

  const fields: FieldsProps = React.useMemo(() => [
    {
      name: 'email',
      type: 'email',
      placeholder: 'Email...',
      disabled: true,
      autoComplete: 'new-password'
    },
    {
      name: 'firstName',
      type: 'text',
      placeholder: 'First name...',
      autoComplete: 'new-password'
    },
    {
      name: 'lastName',
      type: 'text',
      placeholder: 'Last name...',
      autoComplete: 'new-password'
    },
    {
      name: 'affiliation',
      type: 'text',
      placeholder: 'Affiliation...'
    },
    {
      name: 'country',
      type: 'select',
      options: countries.map(country => ({
        label: country.name,
        value: country.name
      }))
    },
    {
      name: 'password',
      type: 'password',
      placeholder: 'Password...',
      autoComplete: 'new-password'
    },
    {
      name: 'passwordVerification',
      type: 'password',
      placeholder: 'Password verification...',
      autoComplete: 'new-password'
    }
  ] as FieldsProps, [])
  
  return (
    <>
      <Form
        fields={ fields }
        initialValues={ {
          token,
          email: decodedToken.data && decodedToken.data.email
        } }
        validate={ (values: any) => {
          const errors: any = {}
          if (!values.firstName)
            errors.firstName = 'Required field'
          if (!values.lastName)
            errors.lastName = 'Required field'
          if (!values.password)
            errors.password = 'Required field'
          if (!values.passwordVerification)
            errors.passwordVerification = 'Required field'
          else if (values.passwordVerification !== values.password)
            errors.passwordVerification = 'Incorrect verification password'
          return errors
        } }
        onSubmit={ (values: CompleteProfilePayload) => actions.completeProfile(values) }
        request={ state.requests['post /users/complete-profile'] }
        submitLabel='Complete your account'
        submitLg
      />
    </>
  )
}

export default UserForm