import * as React from 'react'
import { useSession } from '../stores/session/store'
import PageCenteredContent from './app-pages/page-centered-form'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'

const IsAuth: React.FunctionComponent = () => {
  const [state, actions] = useSession()
  const location = useLocation()
  const history = useHistory()
  const query = queryString.parse(location.search) as { 
    token?: string
    redirect?: string
  }
  React.useEffect(() => {
    actions.isAuth({
      token: query.token,
    })
  }, [])
  
  React.useEffect(() => {
    if (
      state.requests &&
      state.requests['get /is-auth'] && 
      (state.requests['get /is-auth'].status === 'success' ||
      state.requests['get /is-auth'].status === 'error')
    )
      history.push(query.redirect || '/')
  }, [state.requests])

  return (
    <PageCenteredContent>
      Loading
    </PageCenteredContent>
  )
}

export default IsAuth