import * as React from 'react'
import PageCenteredForm from '../app-pages/page-centered-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import PasswordRecoveryForm from './form'
import { Link } from 'react-router-dom'

const PasswordRecovery: React.FunctionComponent = () => (
  <PageCenteredForm 
    title={ 'Password recovery' }
    titleDescription={ 'Receive an email with a link to change your password' }
    icon={ <FontAwesomeIcon icon={ faLock } size='2x' /> }
    footer={ <Link to='/login'>Cancel</Link> }
  >
    <PasswordRecoveryForm />
  </PageCenteredForm>
)

export default PasswordRecovery