import * as React from 'react'
import routes, { getRoute } from '../routes'
import { Switch, Route, useHistory, useLocation, Redirect } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { useSession } from '_stores/session/store'

const Logged: React.FunctionComponent = () => {
  const [state, actions] = useSession()
  const history = useHistory()
  const location = useLocation()
  React.useEffect(() => {
    const currentRoute = getRoute(location.pathname)
    if (!currentRoute)
      return console.log('404') // TODO: 404 page
    if (!currentRoute.logged || !state.token)
      return history.push('/')
    actions.isAuth({ token: state.token })
  }, [])

  return (
    <Container fluid>
      <Switch>
        {
          routes.filter(route => route.logged).map(route =>
            <Route
              exact
              key={ route.path }
              path={ route.path }
              component={ route.component }
            />
          )
        }
        <Route exact path='/'>
          <Redirect to='/membranes' />
        </Route>
      </Switch>
    </Container>
  )
}

export default Logged