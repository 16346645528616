import * as React from 'react'
import Field, { BaseFormFieldProps } from '../index'
import { Form, InputGroup } from 'react-bootstrap'

export interface InputGroupFieldFieldProps extends BaseFormFieldProps {
  type?: 'text' | 'email' | 'password' | 'phone' | 'textarea'
  disabled?: boolean
  field?: any
  autoComplete?: string
}

export const InputGroupField: React.FunctionComponent<InputGroupFieldFieldProps> = (props) => (
  <div className='form-input-group'>
    <Field { ...props }>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text id='inputGroup-sizing-sm'>N/A</InputGroup.Text>
          <InputGroup.Checkbox
            aria-label='Radio button for following text input'
            defaultChecked={ props.group && props.values && props.values[props.group.name] }
            onChange={ (e: any) => props.setFieldValue && props.group &&
                props.setFieldValue(props.group.name, e.target.checked)
            }
          />
        </InputGroup.Prepend>
        <Form.Control
          type={ (props.group && props.group.mainType) || 'text' }
          placeholder={ props.placeholder }
          // isValid={ !Boolean(props.error) }
          //isInvalid={ Boolean(props.error) }
          onChange={ (e) => props.onChange && props.onChange(e.target.value) }
          onBlur={ (e: any) => props.onBlur && props.onBlur(e.target.value) }
          onKeyPress={ (e: any) => props.onEnterPress && e.key === 'Enter' && props.onEnterPress(e.target.value) }
          defaultValue={ props.defaultValue }
          name={ props.field ? props.field.name : props.name }
          disabled={ props.disabled }
          autoComplete={ props.autoComplete }
          autoFocus={ props.autoFocus }
          step='any'
          as={ props.type === 'textarea' ? 'textarea' : undefined }
        />
      </InputGroup>
    </Field>
  </div>
)
export default InputGroupField