import * as React from 'react'
import { Membrane, Status as StatusEnum, IssueStatus } from '_stores/membranes/types'
import classNames from 'classnames'
import { getIssueStatusIcon, getStatusVariant } from '_stores/membranes/utils'
import Status from '_components/status'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faComments } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
import { Button, Toast } from 'react-bootstrap'
import History from '_components/history'
import moment from 'moment'
import MembraneNoteForm from '_containers/membranes/note-form'
import MembraneNote from './note'
import MembraneIssue from './issue'

interface MembraneHistoryProps {
  membrane: Membrane
  schema: any
}

const MembraneHistory: React.FunctionComponent<MembraneHistoryProps> = ({
  membrane,
  schema
}) => {
  const [showNoteForm, setShowNoteForm] = React.useState(false)
  const [showOnlyNotes, setShowOnlyNotes] = React.useState(false)
  const historyItems: any[] = React.useMemo(() => {
    if (!membrane)
      return []
    const membraneHistoryItems = membrane.history && membrane.history.filter(h => 
      !h.title || (!h.title.match('note'))).map((item) => {
      if (item.changes && item.changes[0]) {
        //@ts-ignore
        item.content = () =>
          <div>
            {
              item.changes && item.changes.map((change, index) => {
                const attribute = schema[change.attribute] && schema[change.attribute].labels && schema[change.attribute].labels.short
                return (
                  <div
                    key={ index }
                    className={ classNames({ 'mb-1': item.changes && index !== item.changes.length - 1 }) }
                  >
                    <strong className='mr-2'>
                      {
                        item.title && item.title.match('issue') ?
                          `Issue ${change.attribute}:`
                          :
                          `${attribute || _.upperFirst(change.attribute)}:`
                      }
                    </strong>
                    <span>
                      {
                        change.attribute === 'status' ?
                          <Status
                            status={ change.oldValue }
                            variant={ getStatusVariant(change.oldValue as StatusEnum) }
                            icon={ item.title && item.title.match('issue') && 
                            <FontAwesomeIcon
                              icon={ getIssueStatusIcon(change.oldValue as StatusEnum) }
                            />
                            }
                          />
                          :
                          change.oldValue 
                      }
                      <FontAwesomeIcon icon={ faArrowRight } className='ml-1 mr-1'/>
                      {
                        change.attribute === 'status' ?
                          <Status
                            status={ change.newValue }
                            variant={ getStatusVariant(change.newValue as StatusEnum) }
                            icon={ item.title && item.title.match('issue') && 
                            <FontAwesomeIcon
                              icon={ getIssueStatusIcon(change.newValue as StatusEnum) }
                            />
                            }
                          />
                          :
                          change.newValue 
                      }
                    </span>
                  </div>
                )
              })
            }
          </div>
      }
      return item
    })

    const notes = membrane.notes.map(note => ({
      title: 'Internal note',
      content: () => <MembraneNote note={ note } />,
      user: note.user,
      date: note.date
    }))

    const issues = membrane.issues.map(issue => ({
      title: <span title={ issue.status }>
        <FontAwesomeIcon
          icon={ getIssueStatusIcon(issue.status) }
          className='mr-1'
        />
        Issue
      </span>,
      content: () => <MembraneIssue
        status={ Object.values(IssueStatus) }
        issue={ issue }
        history={
          historyItems.filter(h => 
            h.item && h.item === issue._id && !h.title.match('created')).map(h => ({
            ...h,
            title: '',
            changes: h.changes && h.changes.map((change: any) => ({ ...change, attribute: `Issue ${change.attribute}` }))
          }))
        }
      />,
      user: issue.requestor,
      date: issue.date
    }))

    let items: any[] = [...notes]
    if (!showOnlyNotes)
      items = [...items, ...membraneHistoryItems, ...issues]
    return _.sortBy(items, 'date').reverse()
  }, [membrane, showOnlyNotes])

  return (
    <div className='mt-5'>
      <div className='text-right mb-2'>
        {
          membrane.notes[0] &&
            <Button
              onClick={ () => setShowOnlyNotes(!showOnlyNotes) }
              size='sm'
              variant={ showOnlyNotes ? 'secondary' : 'primary' }
              className='mr-2'
            >
              <FontAwesomeIcon icon={ faComments } />
            </Button>
        }
        {
          !showNoteForm &&
            <Button
              size='sm'
              onClick={ () => setShowNoteForm(true) }
            >
              Add a note
            </Button>
        }
      </div>
      {
        showNoteForm &&
          <Toast>
            <Toast.Header closeButton={ false }>
              <strong className='mr-auto'>New note</strong>
              <small>{ moment().format('DD/MM/YYYY - HH:mm') }</small>
            </Toast.Header>
            <Toast.Body>
              <MembraneNoteForm 
                onCancel={ () => setShowNoteForm(false) }
                onSubmit={ () => setShowNoteForm(false) }
              />
            </Toast.Body>
          </Toast>
      }
      <div>
        <History
          items={ historyItems.filter(h => !h.item) }
        />
      </div>
    </div>
  )
}
export default MembraneHistory