import { createStore, createSubscriber, createHook, createContainer, StoreActionApi } from 'react-sweet-state'
import { ResourceState } from '_core/store'
import { Article } from './types'
import {
  ClearRequestPayload,
  genericActions
} from '_core/store'
import history from '_core/history'

export type ArticlesState = ResourceState<Article>
export type ArticlesApi = StoreActionApi<ArticlesState>

const initialState: ArticlesState = {
  endpoint: '/articles',
  all: [],
  currentId: undefined,
  requests: {}
}

const actions = {
  fetch: () => async ({ dispatch }: ArticlesApi) => {
    await dispatch(genericActions.apiFetch({
      populate: ['report']
    }))
  },
  get: (_id: string) => async ({ dispatch }: ArticlesApi) => {
    await dispatch(genericActions.apiGet(_id))
  },
  create: (data: Article) => async ({ getState, dispatch }: ArticlesApi) => {
    const res = await dispatch(genericActions.apiCreate(data))
    if (res && res.result) {
      const type = res.result.type
      history.push(`/${type === 'release' ? 'releases' : 'news'}/${res.result._id}`)
    }
  },
  update: (data: Article) => async ({ dispatch }: ArticlesApi) => {
    await dispatch(genericActions.apiUpdate(data))
  },
  createOrUpdate: (data: Article) => async ({ dispatch }: ArticlesApi) => {
    if (data.dates && data.dates.publication) {
      //@ts-ignore
      data.dates.publication = new Date(data.dates.publication)
    }
    await dispatch(genericActions.apiCreateOrUpdate(data))
  },
  delete: (_id: string) => async ({ dispatch }: ArticlesApi) => {
    await dispatch(genericActions.apiDelete(_id))
  },
  setCurrent: (article?: Article) => ({ dispatch }: ArticlesApi) => {
    dispatch(genericActions.setCurrent(article))
  },
  clearRequest: (data: ClearRequestPayload) => ({ dispatch }: ArticlesApi) => {
    dispatch(genericActions.clearRequest(data))
  },
  clearRequests: () => ({ dispatch }: ArticlesApi) => {
    dispatch(genericActions.clearRequests())
  },
  getRequest: (method?: string, withId?: boolean) =>
    ({ dispatch }: ArticlesApi) =>
      dispatch(genericActions.getRequest(method, withId))
}

export type ArticlesActions = typeof actions

const Store = createStore<ArticlesState, ArticlesActions>({
  name: 'articles',
  initialState,
  actions
})

export const ArticlesSubscriber = createSubscriber(Store)
export const useArticles = createHook(Store)
export const useCurrentArticle = createHook(Store, {
  selector: (state: ArticlesState) => {
    if (state.currentId) 
      return state.all.find(article => article._id === state.currentId)
    return
  }
})
export const ArticlesContainer = createContainer(Store)

export default Store