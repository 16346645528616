import * as React from 'react'
import { useMembranes } from '_stores/membranes/store'
import Title from '_components/title'
import Table from '_components/table'
import { Link, useHistory } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import moment from 'moment'
import Status from '_components/status'
import { getIssueStatusIcon, getStatusVariant } from '_stores/membranes/utils'
import Form, { FieldsProps } from '_components/form'
import { Status as StatusEnum } from '_stores/membranes/types'
import qs from 'query-string'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import useDidMountEffect from '_core/hooks'

const MembranesList: React.FunctionComponent = () => {
  const [state, actions] = useMembranes()
  const history = useHistory()
  const request = state.requests['get /membranes']

  useDidMountEffect(() => {
    actions.fetch(state.filters)
  }, [state.filters])

  React.useEffect(() => {
    actions.getSchema()
    if (!state.all[1])
      actions.fetch(state.filters)
  }, [])
  
  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'omdId',
        width: '5%',
        Cell: (data: any) => <div className='text-center'>{ data.cell.row.original.omdId }</div>
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: (data: any) =>
          <Link to={ `/membranes/${data.cell.row.original._id}` }>{ data.value }</Link>
      },
      {
        Header: 'Report',
        accessor: 'report.reference',
        Cell: (data: any) => 
          <span>
            { data.cell.row.original.report && data.cell.row.original.report.reference }
          </span>
      },
      {
        Header: 'Report Author',
        accessor: 'report.author',
        Cell: (data: any) => 
          <span>
            { data.cell.row.original.report && data.cell.row.original.report.author.lastName }
          </span>
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: '15%',
        Cell: (data: any) => <Status status={ data.value } variant={ getStatusVariant(data.value) } />
      },
      {
        Header: 'Issues',
        disableSortBy: true,
        accessor: (d: any) => {
          const issuesStatus = {}
          d.issues && d.issues.forEach((issue: any) => {
            if (!issuesStatus[issue.status])
              issuesStatus[issue.status] = 1
            else
              issuesStatus[issue.status] += 1
          })
          return Object.keys(issuesStatus).sort().map((status) =>
            <span className='mr-1' key={ status }>
              <Status
                status={ issuesStatus[status] }
                icon={
                  <FontAwesomeIcon
                    icon={ getIssueStatusIcon(status) }
                  />
                }
              />

            </span>
          )
        },
        width: '5%'
      },
      {
        Header: 'Date',
        width: '15%',
        accessor: 'dates.created',
        Cell: (data: any) => <span>{ moment(data.value).format() }</span>,
      },
    ],
    []
  )

  const filtersFields: FieldsProps = React.useMemo((): FieldsProps => [
    {
      type: 'section',
      name: 'filters',
      section: {
        fields: [
          {
            field: {
              label: 'Search',
              name: 'search',
              type: 'text'
            },
            colProps: {
              lg: 4,
              md: 6
            }
          },
          {
            field: {
              label: 'Status',
              name: 'status',
              type: 'react-select',
              multiple: true,
              options: state.schema ? state.schema.status.validations.enum.map((value: StatusEnum) => ({
                label: <Status status={ value } variant={ getStatusVariant(value) } />,
                value
              })) : []
            },
            colProps: {
              lg: 4,
              md: 6
            }
          },
          {
            field: {
              label: 'Issues',
              name: 'issues.status',
              type: 'react-select',
              multiple: true,
              options: state.schema ? state.schema.issues.item.status.validations.enum.map((value: StatusEnum) => ({
                label: <Status
                  status={ value }
                  icon={
                    <FontAwesomeIcon
                      icon={ getIssueStatusIcon(value) }
                      //className={ `text-${getStatusVariant(value)}` }
                    />
                  }
                />,
                value
              })) : []
            },
            colProps: {
              lg: 4,
              md: 6
            }
          },
        ]
      }
    }
  ], [state.schema])
  
  return (
    <PageApp>
      <Title
        label='Membranes'
        buttons={ [
          {
            label: 'Export to CSV',
            size: 'sm',
            href: `${process.env.API_HOST}/membranes/export?${qs.stringify(state.filters)}`
          },
          {
            label: 'New membrane',
            size: 'sm',
            onClick: () => {
              actions.setDuplicate()
              actions.setCurrent()
              history.push('/membranes/new')
            }
          }
        ] }
      />
      {
        state.schema &&
        <Form
          fields={ filtersFields }
          onSubmit={ (values) => null }
          onFieldChange={ actions.setFilter }
          initialValues={ state.filters }
          noButton
        />
      }
      <div className='mt-3'>
        <Table
          columns={ columns }
          data={ state.all }
          loading={ !request || request.status === 'inProgress' }
        />
      </div>
    </PageApp>
  )
}

export default MembranesList