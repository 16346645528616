import axios, { AxiosInstance, Method } from 'axios'
import { SetState, GetState } from 'react-sweet-state'
import { getStoreState } from './local-storage'
import { ResourceState } from './store'

const baseApi = axios.create({
  baseURL: process.env.API_HOST,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

export interface ApiCallConfig {
  method: Method
  url: string
  params?: any
  data?: any
  headers?: any
  progress?: boolean
  onUploadProgress?(progressEvent: ProgressEvent): void
  requestName?: string
  authToken?: string
}

const api = async (config: ApiCallConfig)
: Promise<{ success?: boolean, data?: any, error?: any, message?: string }> => {
  if (!config.method)
    config.method = 'get'
  if (config.method === 'get' && config.data)
    config.params = config.data

  //Init headers
  let headers: { authorization?: string, [key: string]: any } = {}
  if (config.authToken)
    headers.authorization = config.authToken
  if (config.headers)
    headers = { ...headers, ...config.headers }
  
  //TODO: on upload progress


  try {
    const res = await baseApi.request({
      ...config,
      headers
    })
    return {
      success: true,
      data: res.data
    }
  } catch (err) {
    let message
    if (err.response && err.response.data) {
      if (err.response.data.data && err.response.data.data.message)
        message = err.response.data.data.message
      else if (err.response.data.message)
        message = err.response.data.message
    }
    return {
      success: false,
      error: err,
      message
    }
  }
}

export const callApiFromStore = async (
  config: ApiCallConfig,
  setState: SetState<ResourceState<any>>,
  getState: GetState<ResourceState<any>>)
: Promise<any> => {
  const callDate = new Date().getTime()
  if (!config.method)
    config.method = 'get'
  if (config.method === 'get' && config.data)
    config.params = config.data

  if (config.progress)
    config.onUploadProgress = (progressEvent: ProgressEvent) => setState({
      requests: {
        ...getState().requests,
        [requestKey]: {
          lastCallDate: callDate,
          status: 'inProgress',
          loaded: `${(progressEvent.loaded / 1000).toFixed(0)}KB / ${(progressEvent.total / 1000).toFixed(0)}KB (${((progressEvent.loaded / progressEvent.total) * 100).toFixed(0)}%)`
        }
      }
    })

  const requestKey = config.requestName ?
    config.requestName : `${config.method.toLowerCase()} ${config.url}`

  setState({
    requests: {
      ...getState().requests,
      [requestKey]: {
        lastCallDate: callDate,
        status: 'inProgress'
      }
    }
  })
  try {
    let headers: { authorization?: string } = {}

    //Set authorization token in headers
    const sessionState = getStoreState('session')
    if (sessionState && sessionState.token)
      headers.authorization = sessionState.token

    if (config.headers)
      headers = { ...headers, ...config.headers }

    const res = await baseApi.request({
      ...config,
      headers
    })
    
    setState({
      requests: {
        ...getState().requests,
        [requestKey]: {
          lastCallDate: callDate,
          status: 'success',
          response: res.data,
          message: res.data.message
        }
      }
    })
    return res.data
  } catch (err) {
    let message
    if (err.response && err.response.data) {
      if (err.response.data.data && err.response.data.data.message)
        message = err.response.data.data.message
      else if (err.response.data.message)
        message = err.response.data.message
    }
    setState({
      requests: {
        ...getState().requests,
        [requestKey]: {
          lastCallDate: callDate,
          status: 'error',
          error: err.response && err.response.data,
          message
        }
      }
    })
  }
}

export default api