import * as React from 'react'
import Form from '_components/form'
import { useSession } from '_stores/session/store'

const LoginForm: React.FunctionComponent = () => {
  const [state, actions] = useSession()
  return (
    <Form
      fields={ [
        {
          name: 'email',
          type: 'email',
          placeholder: 'Email...'
        },
        {
          name: 'password',
          type: 'password',
          placeholder: 'Password...'
        }
      ] }
      initialValues={ {} }
      validate={ (values: any) => {
        const errors: any = {}
        if (!values.email)
          errors.email = 'Required field'
        if (!values.password)
          errors.password = 'Required field'
        return errors
      } }
      onSubmit={ actions.login }
      submitLabel='Login'
      submitLg
      request={ state.requests && state.requests['post /auth'] }
    />
  )
}
export default LoginForm