import * as React from 'react'
import Form from '_components/form'
import Loader from '_components/loader'
import { useCurrentReport, useReports } from '_stores/reports/store'

const ReportForm:React.FunctionComponent = () => {
  const [currentReport, actions] = useCurrentReport()
  const [state] = useReports() 
  React.useEffect(() => {
    actions.getSchema()
  }, [])

  if (!state.schema)
    return <Loader />
  return (
    <>
      <Form
        fields={ [
          {
            name: 'reference',
            label: 'Reference',
            type: 'text',
            placeholder: 'Reference...'
          },
          {
            name: 'title',
            label: 'Title',
            type: 'text',
            placeholder: 'Title...'
          },
          {
            name: 'type',
            label: 'Type',
            type: 'select',
            placeholder: 'Type...',
            options: state.schema.type.validations.enum.map((value: string) => ({
              value, label: value
            }))
          },
          {
            name: 'author.lastName',
            label: 'Author last name',
            type: 'text',
            placeholder: 'Author last name...'
          },
          {
            name: 'publicationDate',
            label: 'Publication year',
            type: 'number',
            min: 1900,
            max: new Date().getFullYear(),
            placeholder: 'Author last name...'
          },
        ] }
        initialValues={ currentReport ? { ...currentReport, publicationDate: new Date(currentReport.publicationDate).getFullYear() } : {} }
        validate={ (values: any) => {
          const errors: any = { author: {} }
          if (!values.reference)
            errors.reference = 'Required field'
          if (!values.title)
            errors.title = 'Required field'
          if (!values.author || !values.author.lastName)
            errors.author.lastName = 'Required field'
          if (!Object.keys(errors.author)[0])
            delete errors.author
          return errors
        } }
        onSubmit={ (values) => actions.createOrUpdate({
          ...values,
          publicationDate: new Date(values.publicationDate)
        }) }
        request={ actions.getRequest() }
        submitLabel='Submit'
      />
    </>

  ) }

export default ReportForm