import * as React from 'react'
import { Button } from 'react-bootstrap'

interface TitleButton {
  variant?: string
  label: string | React.ReactNode
  onClick?(): void
  size?: 'sm' | 'lg'
  href?: string
}

export interface TitleProps {
  label: string | React.ReactNode
  className?: string
  level?: 1|2|3|4|5|6|'1'|'2'|'3'|'4'|'5'|'6'
  button?: TitleButton
  buttons?: TitleButton[]
}

const RenderButton: React.FunctionComponent<{ button: TitleButton }> = ({
  button
}) =>
  <Button
    variant={ button.variant || 'secondary' }
    onClick={ button.onClick }
    size={ button.size }
    className='ml-2'
    href={ button.href }
  >
    { button.label }
  </Button>

export const Title: React.FunctionComponent<TitleProps> = ({ label, button, buttons, level = 1, className }) => {
  const H = `h${level}` as any
  return (
    <div
      className={
        `d-flex justify-content-between align-items-center bd-highlight mb-1 ${className}`
      }
    >
      <div>
        <H>{ label }</H>
      </div>
      <div>
        {
          button &&
            <RenderButton 
              button={ button }
            />
        }
        {
          buttons &&
            buttons.map((button, index: number) => 
              <RenderButton
                key={ index }
                button={ button }
              />
            )
        }
      </div>
    </div>
  )
}


export default Title