import { createStore, createSubscriber, createHook, createContainer, StoreActionApi } from 'react-sweet-state'
import { genericActions, ResourceState } from '_core/store'
import { Report } from './types'
import { ClearRequestPayload } from '_core/store'
import history from '_core/history'

export interface ReportsState extends ResourceState<Report> {
  initialized: boolean
  filters: {
    search?: string
  }
  schema: any
}
export type ReportsApi = StoreActionApi<ReportsState>

const initialState: ReportsState = {
  endpoint: '/reports',
  all: [],
  currentId: undefined,
  requests: {},
  schema: undefined,
  filters: {
    search: undefined
  },
  initialized: false
}

const actions = {
  fetch: (query?: any) => async ({ dispatch, setState }: ReportsApi) => {
    if (!query)
      query = {}
    await dispatch(genericActions.apiFetch({
      ...query
    }))
    setState({ initialized: true })
  },
  get: (_id: string) => async ({ dispatch }: ReportsApi) => {
    await dispatch(genericActions.apiGet(_id))
  },
  create: (data: Report) => async ({ getState, dispatch }: ReportsApi) => {
    const res = await dispatch(genericActions.apiCreate(data))
    if (res && res.result)
      history.push(`${getState().endpoint}}/${res.result._id}`)
  },
  update: (data: Partial<Report>) => async ({ dispatch }: ReportsApi) => {
    await dispatch(genericActions.apiUpdate(data))
  },
  createOrUpdate: (data: Partial<Report>) => async ({ dispatch }: ReportsApi) => {
    await dispatch(genericActions.apiCreateOrUpdate(data))
  },
  delete: (_id: string) => async ({ dispatch }: ReportsApi) => {
    await dispatch(genericActions.apiDelete(_id))
  },
  getSchema: () => async ({ dispatch, getState, setState }: ReportsApi) => {
    const res = await dispatch(genericActions.api({
      method: 'get',
      url: `${getState().endpoint}/schema`
    }))
    setState({ schema: res.result })
  },  
  setFilter: (name: string, value: any) => ({ getState, setState }: ReportsApi) => {
    if (!value) {
      const newFilters = { ...getState().filters }
      delete newFilters[name]
      setState({ filters: newFilters })
    } else {
      setState({
        filters: {
          ...getState().filters,
          [name]: value
        }
      })
    }
  },
  setCurrent: (report?: Report) => ({ dispatch }: ReportsApi) => {
    dispatch(genericActions.setCurrent(report))
  },
  clearRequest: (data: ClearRequestPayload) => ({ dispatch }: ReportsApi) => {
    dispatch(genericActions.clearRequest(data))
  },
  clearRequests: () => ({ dispatch }: ReportsApi) => {
    dispatch(genericActions.clearRequests())
  },
  getRequest: (method?: string, withId?: boolean) =>
    ({ dispatch }: ReportsApi) =>
      dispatch(genericActions.getRequest(method, withId))
}

export type ReportsActions = typeof actions

const Store = createStore<ReportsState, ReportsActions>({
  name: 'reports',
  initialState,
  actions
})

export const ReportsSubscriber = createSubscriber(Store)
export const useReports = createHook(Store)
export const useCurrentReport = createHook(Store, {
  selector: (state: ReportsState) => {
    if (state.currentId)
      return state.all.find(report => report._id === state.currentId)
    return 
  }
})
export const ReportsContainer = createContainer(Store)

export default Store