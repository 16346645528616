import * as React from 'react'
import Form from '_components/form'
import { useUsers } from '_stores/users/store'
import { InvitePayload } from '_stores/users/types'

const InviteUserForm: React.FunctionComponent = () => {
  const [state, actions] = useUsers()
  React.useEffect(() => {
    actions.clearRequest({ key: 'post /users/invite' })
  }, [])
  return (
    <Form
      fields={ [
        {
          name: 'email',
          type: 'email',
          placeholder: 'Email...'
        }
      ] }
      initialValues={ {} }
      validate={ (values: any) => {
        const errors: any = {}
        if (!values.email)
          errors.email = 'Required field'
        //if (!values.role)
        //  errors.role = 'Required field'
        return errors
      } }
      onSubmit={ (values: InvitePayload) => actions.invite({
        ...values,
        role: 'admin'
      }) }
      request={ state.requests['post /users/invite'] }
      success={
        () => <span className='text-success'>Email sent to new user</span>  
      }
      submitLabel='Invite user'
      submitLg
    />

  )
}

export default InviteUserForm