import * as React from 'react'
import Form, { FieldsProps } from '_components/form'
import Loader from '_components/loader'
import { useReports } from '_stores/reports/store'
import { useArticles, useCurrentArticle } from '../../stores/articles/store'

interface ArticleFormProps {
  type: 'release' | 'post'
}

const ArticleForm:React.FunctionComponent<ArticleFormProps> = ({
  type
}) => {
  const [currentArticle, actions] = useCurrentArticle()
  const [state] = useArticles()
  const [reportsState, reportsActions] = useReports() 
  const request = state.requests && currentArticle && state.requests[`post /articles/${currentArticle._id}`]

  React.useEffect(() => {
    if (type === 'release')
      reportsActions.fetch({})
  }, [type])

  const fields = React.useMemo(() => {
    const fields:FieldsProps = [
      {
        type: 'section',
        name: 'info',
        section: {
          title: 'Meta data',
          fields: [
            {
              colProps: { md: 12 },
              field: {
                name: 'published',
                type: 'checkbox',
                options: [
                  {
                    value: true,
                    label: 'Published'
                  }
                ]
              }
            },
            {
              colProps: { md: 6 },
              field: {
                name: 'meta.title',
                label: 'Title',
                type: 'text',
                placeholder: 'Name...'
              }
            },
            {
              colProps: { md: 6 },
              field: {
                name: 'meta.slug',
                label: 'Slug (url)',
                type: 'text',
                placeholder: 'Slug...',
                disabled: Boolean(currentArticle && currentArticle.meta.slug)
              }
            },
            {
              colProps: { md: 12 },
              field: {
                name: 'meta.description',
                label: 'Description (meta data)',
                type: 'text',
                placeholder: 'Description...'
              }
            },
            {
              colProps: { md: 12 },
              field: {
                name: 'dates.publication',
                label: 'Publication date',
                type: 'date',
                placeholder: 'Publication date...'
              }
            },
          ]
        }
      },
      {
        type: 'section',
        name: 'content',
        section: {
          title: 'Content',
          fields: [
            {
              colProps: { md: 12 },
              field: {
                name: 'content',
                type: 'editor'
              }
            },
          ]
        }
      }
    ]
    if (type === 'release') {
      fields.unshift({
        name: 'report',
        type: 'select',
        label: 'Report reference (DOI, product datasheet reference, patent number)',
        options: reportsState.all.map(report => ({
          label: report.reference,
          value: report._id
        }))
      })
    }
    return fields
  }, [type, reportsState.all, currentArticle])

  if (request && request.status === 'inProgress')
    return <Loader />

  return (
    <>
      <Form
        fields={ fields }
        noSubmitOnEnter
        initialValues={ currentArticle ? currentArticle : {
          content: [ 
            {
              type: 'header',
              data: {
                text: 'Title',
                level: 1
              }
            },
          ]
        } }
        validate={ (values: any) => {
          const errors: any = { meta: { }, dates: {} }
          values.type = type
          if (!values.meta || !values.meta.title)
            errors.meta.title = 'Required field'

          if (!values.meta || !values.meta.description)
            errors.meta.description = 'Required field'
          else if (values.meta.description.length > 140)
            errors.meta.description = 'Maximum 140 characters'

          if (!values.dates || !values.dates.publication)
            errors.dates.publication = 'Required field'
          

          if (!Object.keys(errors.meta)[0])
            delete errors.meta

          if (!Object.keys(errors.dates)[0])
            delete errors.dates
          return errors
        } }
        onSubmit={ actions.createOrUpdate }
        request={ actions.getRequest() }
        submitLabel='Submit'
      />
    </>
  )
}

export default ArticleForm