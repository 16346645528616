import * as React from 'react'
import Field, { BaseFormFieldProps } from '..'
import Dropzone from 'react-dropzone'

export interface FilesFieldProps extends BaseFormFieldProps {
}

const FilesField: React.FunctionComponent<FilesFieldProps> = (props) => 
  <Field { ...props }>
    <Dropzone>
      {({ getRootProps, getInputProps }) => (
        <section>
          <div { ...getRootProps() }>
            <input { ...getInputProps() } />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>
        </section>
      )}
    </Dropzone>
  </Field>

export default FilesField