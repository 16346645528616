import * as React from 'react'
import PageCenteredContent from './page-centered-content'

interface PageCenteredFormProps {
  title?: string | React.ReactNode 
  icon?: React.ReactNode
  titleDescription?: string
  children: React.ReactNode
  footer?: string | React.ReactNode 
}

const PageCenteredForm: React.FunctionComponent<PageCenteredFormProps> = (props) => (
  <PageCenteredContent
    className='centered-form'
  >
    <div className='form-header'>
      <div className='title'>
        { props.title }
        { props.titleDescription && <small>{ props.titleDescription }</small> }
      </div>
      <div className='icon'>
        { props.icon }
      </div>
    </div>
    <div className='form-body'>
      <div className='form-body__content'>
        { props.children }
      </div>
    </div>
    {
      props.footer &&
      <div className='form-footer'>
        { props.footer }
      </div>
    }
  </PageCenteredContent>
)

export default PageCenteredForm