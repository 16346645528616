import * as React from 'react'
import Field, { BaseFormFieldProps } from '..'
import { Button } from 'react-bootstrap'
import Medias from '_components/medias'
import { useMedias } from '_stores/medias/store'
import { Media } from '_stores/medias/types'
import MediasModal from '_components/medias/modal'

export interface MediasFieldProps extends BaseFormFieldProps {
  multiple?: boolean
}

const MediasField: React.FunctionComponent<MediasFieldProps> = (props) => {
  const [state] = useMedias()
  
  //Modal display
  const [modalIsVisible, setModalVisible] = React.useState(false)
  //Fetch medias
  /*React.useEffect(() => {
    actions.fetch()
  }, [])*/

  //Manage used medias from default value
  const [usedMedias, setUsedMedias] = React.useState([] as Media[])
  React.useEffect(() => {
    if (props.defaultValue && props.defaultValue[0])
      setUsedMedias(state.all.filter(media => props.defaultValue.includes(media._id)))
  }, [props.defaultValue, state.all])
  
  return (
    <>
      <MediasModal
        modalIsVisible={ modalIsVisible }
        setModalVisible={ setModalVisible }
        medias={ state.all }
        defaultValue={ props.defaultValue }
        onSubmit={ (selectedMedias) => {
          props.form && props.form.setFieldValue(props.field.name, selectedMedias)
          if (props.onEnterPress)
            props.onEnterPress(selectedMedias)
          setModalVisible(false)
        } }
      />
      <Field { ...props }>
        <Button onClick={ () => setModalVisible(true) }>
          Select medias
        </Button>
      </Field>
      <Medias
        medias={ usedMedias }
        readOnly
      />
    </>
  )
}

export default MediasField