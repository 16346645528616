import * as React from 'react'
import { ApiRequest } from '_core/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

interface RequestMessageProps {
  request?: ApiRequest
  loader?: boolean
  check?: boolean
}

const RequestMessage: React.FunctionComponent<RequestMessageProps> = ({ request, loader, check }) => {
  const [successCheck, displaySuccessCheck] = React.useState(false)
  if (!request)
    return <></>

  if (check) {
    React.useEffect(() => {
      if (request && request.status === 'success')
        displaySuccessCheck(true)
      setTimeout(() => {
        displaySuccessCheck(false)
      }, 3000)
    }, [request.status])
  }

  if (request && request.status === 'inProgress' && loader)
    return <FontAwesomeIcon icon={ faSpinner } spin className='mr-1 ml-1'/>

  return (
    <span className={ request.status }>
      { successCheck && <FontAwesomeIcon icon={ faCheckCircle } className='mr-1 ml-1 text-success'/> }
      { request.message }
    </span>
  )
}

export default RequestMessage