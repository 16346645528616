import * as React from 'react'
import { Toast } from 'react-bootstrap'
import UserName from '_components/user-name'
import moment from 'moment'

export interface HistoryItem {
  title: string | React.ReactElement
  user?: {
    firstName: string
    lastName: string
    [key: string]: any
  },
  date?: string
  content?(): React.Component
}

interface HistoryProps {
  items: HistoryItem[]
}

const History: React.FunctionComponent<HistoryProps> = ({
  items
}) => (
  <div className='history'>
    {
      items.map((item, index) =>
        <Toast key={ index }>
          <Toast.Header closeButton={ false }>
            <strong className='mr-auto'>{ item.title }</strong>
            <small>
              <UserName
                user={ item.user }
                after={ ` @ ${moment(item.date).format('DD/MM/YYYY - HH:mm')}` }
                mode='short'
              />
            </small>
          </Toast.Header>
          { item.content &&
            <Toast.Body>
              { item.content() }
            </Toast.Body>
          }
        </Toast>
      )
    }
  </div>
)


export default History