import * as React from 'react'
import { useTable, useSortBy, Column, usePagination } from 'react-table'
import { Button, ButtonGroup, Form, Table as BsTable } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router'
import qs from 'query-string'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import Loader from '_components/loader'
import useDidMountEffect from '_core/hooks'

export interface TableProps {
  columns: Column[]
  data: any[]
  onRowClick?(row: any): void
  pagination?: boolean
  defaultPage?: number
  onPageChange?(pageIndex: number): void
  loading?: boolean
}

const TablePagination: React.FunctionComponent<any> = ({
  pageIndex,
  pageOptions,
  gotoPage,
  canPreviousPage,
  previousPage,
  nextPage,
  canNextPage,
  pageSize,
  setPageSize,
  pageCount,
  itemsCount,
}) => 
  <div className='pagination mb-3 flex'>
    { /*<div className='d-flex align-items-center'>
          <ButtonGroup>
            <Button onClick={ () => gotoPage(0) } disabled={ !canPreviousPage }>
              {'<<'}
            </Button>
            <Button onClick={ () => previousPage() } disabled={ !canPreviousPage }>
              {'<'}
            </Button>
            <Button onClick={ () => nextPage() } disabled={ !canNextPage }>
              {'>'}
            </Button>
            <Button onClick={ () => gotoPage(pageCount - 1) } disabled={ !canNextPage }>
              {'>>'}
            </Button>
          </ButtonGroup>
  </div> */ }
    { /*<div className='d-flex align-items-center ml-1'>
          | Go to page&nbsp;
          <Form.Control 
            type='number'
            min={ 0 }
            defaultValue={ pageIndex + 1 }
            onChange={ e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            } }
            style={ { width: '100px' } }
          />
          </div>*/ }
        
    <div className='d-flex mr-2'>
      <span>
            Page{' '}
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong> <br /><small>{itemsCount} items in total</small>
      </span>
    </div>
    <div className='ml-auto mr-auto'>

      <ButtonGroup>
        <Button variant='secondary' size='sm' onClick={ () => gotoPage(0) } disabled={ !canPreviousPage }>
          {'<<'}
        </Button>
        <Button variant='secondary' size='sm' onClick={ () => previousPage() } disabled={ !canPreviousPage }>
          {'<'}
        </Button>
        <Button variant='secondary' size='sm' onClick={ () => nextPage() } disabled={ !canNextPage }>
          {'>'}
        </Button>
        <Button variant='secondary' size='sm' onClick={ () => gotoPage(pageCount - 1) } disabled={ !canNextPage }>
          {'>>'}
        </Button>
      </ButtonGroup>
    </div>
    <div className='d-flex'>
      <Form.Control
        value={ pageSize }
        size='sm'
        onChange={ e => {
          setPageSize(Number(e.target.value))
        } }
        as='select'
        defaultValue={ pageSize }
      >
        {[10, 25, 50, 100, 250, itemsCount].sort((a, b) => a - b).filter(count => count <= itemsCount).map(pageSize => (
          <option key={ pageSize } value={ pageSize }>
                Show {pageSize}
          </option>
        ))}
      </Form.Control>
    </div>
  </div>

const Table: React.FunctionComponent<TableProps> = ({
  columns, data, onRowClick, defaultPage, onPageChange, loading
}) => {
  const history = useHistory()
  const location = useLocation()

  const defaultPageIndex = React.useMemo(() => {
    const query = qs.parse(location.search)
    return query.page ? Number(query.page) : 0
  }, [data])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: defaultPageIndex || 0, pageSize: 50 },
      autoResetPage: false
    },
    useSortBy,
    usePagination
  )
  
  React.useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: `?page=${pageIndex}`
    })
  }, [pageIndex])

  useDidMountEffect(() => {
    gotoPage(0)
  }, [data])


  if (loading)
    return <Loader />

  return (
    <>
      <TablePagination
        pageIndex={ pageIndex }
        pageOptions={ pageOptions }
        gotoPage={ gotoPage }
        canPreviousPage={ canPreviousPage }
        previousPage={ previousPage }
        nextPage={ nextPage }
        canNextPage={ canNextPage }
        pageSize={ pageSize }
        setPageSize={ setPageSize }
        pageCount={ pageCount }
        itemsCount={ data.length }
      />
      <BsTable { ...getTableProps() } striped bordered>
        <thead>
          { headerGroups.map(headerGroup => (
            <tr { ...headerGroup.getHeaderGroupProps() }>
              { headerGroup.headers.map((column: any) => (
                <th { ...column.getHeaderProps(column.getSortByToggleProps()) } width={ column.width }>
                  { column.render('Header') }
                  <span>
                    { column.isSorted
                          && <FontAwesomeIcon className='ml-1' icon={ column.isSortedDesc ? faCaretDown : faCaretUp } />
                    }
                  </span>
                </th>
              )) }
            </tr>
          ))}
        </thead>
        <tbody { ...getTableBodyProps() }>
          { page.map(
            (row, i) => {
              prepareRow(row)
              const props = row.getRowProps()
              return (
                <tr { ...props } onClick={ () => onRowClick && onRowClick(row) }>
                  { row.cells.map(cell => (
                    <td { ...cell.getCellProps() }>{ cell.render('Cell') }</td>
                  )) }
                </tr>
              ) }
          ) }
        </tbody>
      </BsTable>
      <TablePagination
        pageIndex={ pageIndex }
        pageOptions={ pageOptions }
        gotoPage={ gotoPage }
        canPreviousPage={ canPreviousPage }
        previousPage={ previousPage }
        nextPage={ nextPage }
        canNextPage={ canNextPage }
        pageSize={ pageSize }
        setPageSize={ setPageSize }
        pageCount={ pageCount }
        itemsCount={ data.length }
      />
    </>
  )
}

export default Table