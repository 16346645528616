import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import History, { HistoryItem } from '_components/history'
import { useMembranes } from '_stores/membranes/store'
import { IssueSchema, IssueStatus } from '_stores/membranes/types'
import { getIssueStatusIcon, getStatusVariant } from '_stores/membranes/utils'
import Status from '_components/status'
import UserName from '_components/user-name'

export interface MembraneIssueProps {
  issue: IssueSchema
  status: IssueStatus[]
  history: HistoryItem[]
}

const MembraneIssue: React.FunctionComponent<MembraneIssueProps> = ({
  issue,
  status,
  history
}) => {
  const [state, actions] = useMembranes()
  
  return (
    <div className='issue' title={ issue.status }>
      <div className='body'>
        <div className='actions'>
          <DropdownButton
            title={ <FontAwesomeIcon icon={ getIssueStatusIcon(issue.status) } /> } 
            size='sm'
            variant='secondary'
          >
            {
              status.filter(s => s !== issue.status).map(status => 
                <Dropdown.Item
                  key={ status }
                  onClick={ () => state.currentId && actions.updateIssue({
                    membraneId: state.currentId,
                    issueId: issue._id,
                    status
                  }) }
                >
                  <Status
                    status={ status }
                    icon={ 
                      <FontAwesomeIcon
                        icon={ getIssueStatusIcon(status) }
                      />
                    }
                  />
                </Dropdown.Item>
              )
            }
          </DropdownButton>
        </div>
        <blockquote>
          { issue.comment }
        </blockquote>
        { issue.requestor &&
        <div className='mt-3'>
          <strong>External requestor</strong>:<br />
          <UserName user={ issue.requestor }/> - { issue.requestor.email }
        </div>
        }
      </div>
      <div className='mt-3'>
        <History
          items={ history }
        />
      </div>
    </div>
  )
}

export default MembraneIssue