import { faStop } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

interface StatusProps {
  status: string,
  variant?: string
  icon?: React.ReactElement
}

const Status: React.FunctionComponent<StatusProps> = ({
  status,
  variant,
  icon
}) => (
  <span>
    <span className='mr-1'>
      {
        icon ? 
          icon
          :
          <FontAwesomeIcon
            icon={ faStop }
            className={ `text-${variant}` }
          />
      }
    </span>
    { status }
  </span>
)
export default Status