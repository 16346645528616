import * as React from 'react'
import { useArticles } from '_stores/articles/store'
import Title from '_components/title'
import Table from '_components/table'
import { Link, useHistory, useLocation } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Column } from 'react-table'
import moment from 'moment'

const ArticlesList: React.FunctionComponent = () => {
  const [state, actions] = useArticles()
  const history = useHistory()
  const location = useLocation()
  const isReleases = location.pathname.match('releases')

  React.useEffect(() => {
    actions.fetch()
  }, [])

  const items = React.useMemo(() => state.all.filter(article =>
    isReleases ? article.type === 'release' : article.type === 'post'
  ), [location.pathname, state.all])
  
  const columns = React.useMemo(
    () => {
      const columns = [
        {
          Header: 'Title',
          accessor: (d: any) => 
            <Link to={ `/${isReleases ? 'releases' : 'news' }/${d._id}` }>
              { d.meta && d.meta.title ? d.meta.title : 'N/A' }
            </Link>,
        },
        {
          Header: 'Date',
          width: '15%',
          accessor: (d: any) => <span>{ moment(d.dates.created).format() }</span>,
        },
        {
          Header: 'Pub.',
          accessor: (d: any) => d.published && <div className='text-center'><FontAwesomeIcon icon={ faCheck }/></div>,
          width: '5%',
        },
      ] as Column[]
      if (isReleases) {
        columns.splice(1, 0,
          {
            Header: 'Report',
            width: '30%',
            accessor: 'report.reference',
            Cell: (data: any) => <div className='text-center'>{ data.cell.row.original.report && data.cell.row.original.report.reference }</div>
          }
        )
      }
      return columns
    },
    []
  )
  

  return (
    <PageApp>
      <Title
        label={ isReleases ? 'Releases' : 'News' }
        button={ {
          label: isReleases ? 'New release' : 'New post',
          onClick: () => {
            actions.setCurrent()
            if (isReleases)
              history.push('/releases/new')
            else
              history.push('/news/new')
          }
        } }
      />
      <div className='mt-3'>
        <Table
          columns={ columns }
          data={ items }
        />
      </div>
    </PageApp>
  )
}

export default ArticlesList