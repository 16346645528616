import * as React from 'react'
import { Carousel as BsCarousel } from 'react-bootstrap'
import CloudinaryImage from '_components/cloudinary-image'
import { Media } from '_stores/medias/types'

export interface CarouselProps {
  medias: Media[]
  onSelect?(selectedIndex: number): void
  activeIndex?: number
}

const Carousel: React.FunctionComponent<CarouselProps> = ({
  medias,
  onSelect,
  activeIndex
}) => 
  <BsCarousel
    activeIndex={ activeIndex || 0 }
    onSelect={ onSelect }
  >
    {
      medias.map(media => 
        <BsCarousel.Item key={ media._id }>
          <CloudinaryImage
            publicId={ media.providerId }
          />
          <BsCarousel.Caption className='bg-dark'>
            <h3>{ media.name }</h3>
            <p>{ media.bytes / 1000 } KB</p>
          </BsCarousel.Caption>
        </BsCarousel.Item>  
      )
    }
  </BsCarousel>

export default Carousel