import * as React from 'react'
import { useReports } from '_stores/reports/store'
import Title from '_components/title'
import Table from '_components/table'
import { Link } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import moment from 'moment'
import _ from 'lodash'
import Form, { FieldsProps } from '_components/form'
import { useMembranes } from '_stores/membranes/store'
import { Report } from '_stores/reports/types'

const ReportsList: React.FunctionComponent = () => {
  const [state, actions] = useReports()
  const [membranesState, membranesActions] = useMembranes()

  React.useEffect(() => {
    actions.fetch(state.filters)
  }, [state.filters])


  React.useEffect(() => {
    membranesActions.fetch()
  }, [])

  const filtersFields: FieldsProps = React.useMemo((): FieldsProps => [
    {
      type: 'section',
      name: 'filters',
      section: {
        fields: [
          {
            field: {
              label: 'Search',
              name: 'search',
              type: 'text'
            },
            colProps: {
              lg: 4,
              md: 6
            }
          }
        ]
      }
    }
  ], [state.schema])
  
  const columns = React.useMemo(
    () => [
      {
        Header: 'Reference',
        accessor: 'reference',
        Cell: (data: any) => {
          const report: Report = data.cell.row.original
          return <Link to={ `/reports/${report._id}` }>{ report.reference }</Link>
        }
      },
      {
        Header: 'Title',
        accessor: 'title'
      },
      {
        Header: 'Type',
        accessor: 'type'
      },
      {
        Header: 'Author',
        accessor: 'author.lastName',
        Cell: (data: any) => {
          const report: Report = data.cell.row.original
          return <span>{ report.author.lastName }</span>
        }
      },
      {
        Header: 'Memb.',
        accessor: 'membranesCount',
        width: '5%',
        Cell: (data: any) => {
          const report: Report = data.cell.row.original
          return <span>{ report.membranesCount }</span>
        }
      },
      {
        Header: 'Date',
        width: '15%',
        accessor: 'dates.created',
        Cell: (data: any) => {
          const report: Report = data.cell.row.original
          return <span>{ moment(report.dates.created).format() }</span>
        }
      },
    ],
    []
  )

  const reports = React.useMemo(() => { 
    const allReports = [...state.all]
    allReports.forEach(report => {
      report.membranesCount = membranesState.all.filter(membrane => membrane.report && membrane.report._id && membrane.report._id === report._id).length
    })
    return _.sortBy(allReports, 'reference')
  }, [state.all, membranesState.all])

  return (
    <PageApp>
      <Title
        label='Reports'
      />
      <Form
        fields={ filtersFields }
        onSubmit={ (values) => null }
        onFieldChange={ actions.setFilter }
        initialValues={ state.filters }
        noButton
      />
      <div className='mt-3'>
        <Table
          columns={ columns }
          data={ reports }
        />
      </div>
    </PageApp>
  )
}

export default ReportsList