import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useResourceFromParams } from '_core/store'
import Loader from '_components/loader'

interface ApiItemWrapperProps {
  children: React.ReactNode
  item?: any
  actions: any
}

const ApiItemWrapper: React.FunctionComponent<ApiItemWrapperProps> = ({
  children,
  item,
  actions
}) => {
  const locationParams: any = useParams()
  useResourceFromParams(actions)
  if (locationParams.id === 'new' || item)
    return <>{ children }</>
  
  return <Loader/>
}

export default ApiItemWrapper