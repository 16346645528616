import * as React from 'react'
import Field, { BaseFormFieldProps } from '../index'
import { Form } from 'react-bootstrap'

export interface InputFieldProps extends BaseFormFieldProps {
  type?: 'text' | 'email' | 'password' | 'phone' | 'textarea' | 'number' | 'date'
  disabled?: boolean
  field?: any
  autoComplete?: string
}

export const InputField: React.FunctionComponent<InputFieldProps> = (props) => {
  const inputRef = React.createRef()


  const defaultValue = React.useMemo(() => {
    if (props.type === 'date' && props.defaultValue && props.defaultValue.substring) {
      return props.defaultValue.substring(0, 10)
    }
    return props.defaultValue   
  }, [])
    
  return (
    <Field { ...props }>
      <Form.Control
        ref={ inputRef }
        type={ props.type }
        placeholder={ props.placeholder }
        //isValid={ !Boolean(props.error) }
        //isInvalid={ Boolean(props.error) }
        onChange={ (e) => props.onChange && props.onChange(e.target.value) }
        onBlur={ (e: any) => props.onBlur && props.onBlur(e.target.value) }
        onKeyPress={ (e: any) => props.onEnterPress && e.key === 'Enter' && props.onEnterPress(e.target.value) }
        defaultValue={ defaultValue }
        name={ props.field ? props.field.name : props.name }
        disabled={ props.disabled }
        autoComplete={ props.autoComplete }
        autoFocus={ props.autoFocus }
        //min={ props.min }
        //max={ props.max }
        step='any'
        as={ props.type === 'textarea' ? 'textarea' : undefined }
        onWheel={ () => props.type === 'number' && inputRef && inputRef.current && inputRef.current.blur() }
      //novalidate
      />
    </Field>
  )
}
export default InputField