import * as React from 'react'
import { Router } from 'react-router-dom'
import Logged from './logged'
import Anonymous from './anonymous'
import { useSession } from '_stores/session/store'
import history from '_core/history'

const App: React.FunctionComponent = () => {
  const [state] = useSession()
  const MainComponant = state.logged ? Logged : Anonymous

  return (
    <Router history={ history }>
      <MainComponant />
    </Router>
  )
}

export default App