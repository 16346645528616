import * as React from 'react'
import { Col } from 'react-bootstrap'
import classNames from 'classnames'

interface MediaThumbnailProps {
  onClick?(): void
  children: React.ReactNode | string
  className?: string
}


const MediaThumbnail: React.FunctionComponent<MediaThumbnailProps> = ({
  onClick,
  children,
  className
}) =>
  <Col
    lg={ 2 }
    md={ 4 }
    sm={ 6 }
    xs={ 6 }
    onClick={ onClick }
    className={ classNames('mb-3', className) }
  >
    { children }
  </Col>

export default MediaThumbnail