import * as React from 'react'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'

import Dropzone from 'react-dropzone'
import { ApiRequest } from '_core/api'
import Loader from '_components/loader'

interface OverlayDropZoneProps {
  onDrop(files: any[]): void
  forceShow?: boolean
  onHide(): void 
  request?: ApiRequest
  accept?: string
}

const OverlayDropZone: React.FunctionComponent<OverlayDropZoneProps> = ({
  onDrop,
  forceShow,
  onHide,
  request,
  accept
}) => {
  let lastDragEvent = new Date().getTime()
  const [isVisible, setVisible] = React.useState(false)
  const show = () => { 
    lastDragEvent = new Date().getTime()
    setVisible(true)
  }
  const hide = () => { 
    const now = new Date().getTime()
    lastDragEvent = now
    setTimeout(() => {
      if (now === lastDragEvent)
        setVisible(false)
    }, 100)
  }
  React.useEffect(() => {
    window.addEventListener('dragover', show)
    window.addEventListener('dragleave', hide)
    return () => {
      window.removeEventListener('dragover', show)
      window.removeEventListener('dragleave', hide)
    }
  }, [])

  React.useEffect(() => {
    if (forceShow !== undefined)
      setVisible(forceShow)
  }, [forceShow])

  React.useEffect(() => {
    if (!isVisible)
      onHide()
  }, [isVisible])
  return (
    <Dropzone 
      accept={ accept }
      onDrop={ acceptedFiles => {
        onDrop(acceptedFiles)
      } }
    >
      {({ getRootProps, getInputProps }) => (
        <section
          className={ classNames('overlay-dropzone', { hide: !isVisible }) }
        >
          {
            request && request.status === 'inProgress' ?
              <div className='in-progress'>
                <Loader 
                  message={ request.loaded }
                />
              </div>
              :
              <div { ...getRootProps() } className='input'>
                <div>
                  { isVisible && <input { ...getInputProps() } /> }
                  <p>Drag some files here or </p>
                  <Button>
                  Select files
                  </Button>
                  <Button
                    variant='dark'
                    className='ml-3'
                    onClick={ (e) => {
                      e.stopPropagation()
                      setVisible(false)
                    } }
                  >
                  Cancel
                  </Button>
                </div>
              </div>
          }
        </section>
      )}
    </Dropzone>
  )
}

export default OverlayDropZone