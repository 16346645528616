import * as React from 'react'
import classnames from 'classnames'
import Sidebar from '_components/sidebar'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

interface PageAppProps {
  children: React.ReactNode
  className?: string
}

const PageApp: React.FunctionComponent<PageAppProps> = (props) => { 
  const [collapsed, setCollapsed] = React.useState(false)
  const [toggled, setToggled] = React.useState(false)
  return (
    <div className={ classnames('app', props.className) }>
      <Sidebar
        collapsed={ collapsed }
        toggled={ toggled }
        setToggled={ setToggled }
      />
      <Button
        onClick={ () => setToggled(!toggled) }
        className='sidebar-toggle'
      >
        <FontAwesomeIcon icon={ faBars } />
      </Button>
      <div className='content'>
        { props.children }
      </div>
    </div>
  )
}
export default PageApp