import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserFriends, faUserCircle, faSignOutAlt, faIdCard, faImages, faNewspaper, faTint, faStickyNote, faFileAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { ProSidebar, Menu, SidebarContent, SidebarHeader, SubMenu } from 'react-pro-sidebar'
import SidebarLink from './link'
import { useAppUser } from '_stores/session/store'
import { useLocation } from 'react-router-dom'
import { useMembranes } from '_stores/membranes/store'

interface SideBarProps {
  collapsed?: boolean
  toggled?: boolean
  setToggled(toggled: boolean): void
}

const Sidebar: React.FunctionComponent<SideBarProps> = ({
  collapsed, 
  toggled,
  setToggled
}) => {
  const [appUser] = useAppUser()
  const location = useLocation()
  const [membranesState, membranesActions] = useMembranes()
  
  return (
    <ProSidebar
      className='sidebar'
      breakPoint='md'
      collapsed={ collapsed }
      toggled={ toggled }
    >
      <SidebarHeader>
        <div className='sidebar_header'>
          OMD
        </div>
      </SidebarHeader>
      <SidebarContent>
        <Menu>
          <SubMenu
            title={ `${appUser.firstName} ${appUser.lastName}` }
            icon={ <FontAwesomeIcon icon={ faUserCircle } /> }
          >
            <SidebarLink
              to='/me'
              setToggled={ setToggled }
              icon={ <FontAwesomeIcon icon={ faIdCard } /> }
              label={ <span>My profile</span> }
            />
            <SidebarLink
              to='/logout'
              setToggled={ setToggled }
              icon={ <FontAwesomeIcon icon={ faSignOutAlt } /> }
              label={ <span>Logout</span> }
            />
          </SubMenu>
        </Menu>
        <Menu>
          <SidebarLink
            to='/membranes'
            setToggled={ setToggled }
            icon={ <FontAwesomeIcon icon={ faTint } /> }
            label={ <span>Membranes</span> }
          />
          {
            location.pathname.match('membranes') ?
              <SidebarLink
                to='/membranes/new'
                setToggled={ setToggled }
                icon={ <FontAwesomeIcon icon={ faPlusCircle } /> }
                label={ <span>New membrane</span> }
                className='sub-link'
                onClick={ () => {
                  membranesActions.setDuplicate()
                  membranesActions.setCurrent()
                } }
              />
              : <span />
          }
          <SidebarLink
            to='/reports'
            setToggled={ setToggled }
            icon={ <FontAwesomeIcon icon={ faStickyNote } /> }
            label={ <span>Reports</span> }
          />
          <SidebarLink
            to='/news'
            setToggled={ setToggled }
            icon={ <FontAwesomeIcon icon={ faNewspaper } /> }
            label={ <span>News</span> }
          />
          { /*<SidebarLink
            to='/releases'
            setToggled={ setToggled }
            icon={ <FontAwesomeIcon icon={ faFileAlt } /> }
            label={ <span>Releases</span> }
          /> */ }
          <SidebarLink
            to='/medias'
            setToggled={ setToggled }
            icon={ <FontAwesomeIcon icon={ faImages } /> }
            label={ <span>Medias</span> }
          />
          <SidebarLink
            to='/users'
            setToggled={ setToggled }
            icon={ <FontAwesomeIcon icon={ faUserFriends } /> }
            label={ <span>Users</span> }
          />
        </Menu>
      </SidebarContent>
    </ProSidebar>
  ) }

export default Sidebar