import * as React from 'react'
import InputField from '../input'
import { BaseFormFieldProps } from '../index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

export interface PasswordFieldProps extends BaseFormFieldProps {}

export const PasswordField = (props: BaseFormFieldProps) => {
  const [passwordVisible, showPassword] = React.useState(false)
  return (
    <InputField 
      { ...props }
      prepend= {
        <FontAwesomeIcon
          icon={ passwordVisible ? faEyeSlash : faEye }
        />
      }
      onPrependClick={ () => showPassword(!passwordVisible) }
      type={ passwordVisible ? 'text' : 'password' }
    />
  )
}

export default PasswordField