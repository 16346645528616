import { User } from '@sentry/react'
import { Report } from '_stores/reports/types'

export enum Types {
  commercial = 'Commercial',
  patent = 'Patent',
  labMade = 'Lab-made'
}

export enum Structure {
  polymericTFC = 'Polymeric TFC',
  asymmetric = 'Asymmetric',
  hybrid = 'TFN / Hybrid',
  inorganic = 'Inorganic',
  other = 'Other'
}

export enum Chemistry {
  celluloseAcetate = 'Cellulose acetate',
  celluloseTriacetate = 'Cellulose triacetate',
  chitinChitosanBased = 'Chitin/chitosan-based',
  polyamide = 'Polyamide',
  polyamine = 'Polyamine',
  polyaniline = 'Polyaniline',
  polyaryleneSulfideSulfone = 'Polyarylene sulfide sulfone',
  polybenzimidazole = 'Polybenzimidazole',
  polyDimethylSiloxane = 'Poly(dimethyl siloxane)',
  polydopamine = 'Polydopamine',
  polyelectrolyte = 'Polyelectrolyte',
  polyester = 'Polyester',
  polyetheretherketone = 'Polyetheretherketone',
  polyethersulfone = 'Polyethersulfone',
  polyethyleneimine = 'Polyethyleneimine',
  polyimide = 'Polyimide',
  polymersOfIntrinsicMicroporosity = 'Polymers of intrinsic microporosity',
  polyPhenylsulfone = 'Poly-phenylsulfone',
  polysulfonamides = 'Polysulfonamides',
  other = 'Other'
}



export enum Modification {
  noModification = 'No modification',
  synthesisDeposition = 'Synthesis: deposition method',
  synthesisAdditives = 'Synthesis: additives',
  synthesisMonomers = 'Synthesis: monomers',
  synthesisSolvent = 'Synthesis: solvent',
  synthesisSupport = 'Synthesis: support',
  postTreatmentSolventAnnealing = 'Post-treatment: solvent annealing',
  postTreatmentSolventActivation = 'Post-treatment: solvent activation',
  postTreatmentSurface = 'Post-treatment: surface',
  other = 'Other'
}

export enum FiltrationMode {
  crossFlow = 'Cross-flow',
  deadEnd = 'Dead-end'
}

export enum Characterization {
  ftir = 'FTIR',
  xps = 'XPS',
  afm = 'AFM',
  tomography = 'Tomography',
  ellipsometry = 'Ellipsometry',
  sem = 'SEM',
  tem = 'TEM',
  zetaPotential = 'Zeta',
  pals = 'PALS',
  erd = 'ERD'
}

export enum Status {
  toValidate = 'To validate',
  detailsRequired = 'Details required',
  accepted = 'Accepted',
  rejected = 'Rejected'
}

export enum IssueStatus {
  open = 'Open',
  inProgress = 'In progress',
  closed = 'Closed'
}

export interface HistoryChange {
  attribute: string
  oldValue: string
  newValue: string
}

export interface HistorySchema {
  _id?: string
  date?: Date | string
  user: string | User
  title?: string
  item?: string
  changes?: HistoryChange[]
}

export interface NoteSchema {
  _id?: string
  date?: Date | string
  user: string | User
  comment: string
}

export interface IssueSchema {
  _id: string
  date: Date | string
  comment: string
  requestor: {
    firstName: string
    lastName: string
    email: string
  }
  status: IssueStatus
}

export interface Membrane {
  _id?: string
  dates: {
    created: string
    lastUpdate: string
  }
  name: string
  omdId: string
  status: Status
  creator: string | User
  report: string | Report
  type: Types
  structure: Structure
  structureOther?: string
  chemistry: Chemistry[]
  chemistryOther?: string
  modification: Modification[]
  modificationOther?: string
  data: {
    a: number
    b: number
    ab: number
    rreal: number
    robs: number
    pw?: number
    ps?: number
    filtrationMode: FiltrationMode
    hydraulicP: number
    naClConcM: number
    otherSolutes: boolean
    osmoticP: number
    cp: number
    ph?: number
    couponSize?: number
    thickness?: number
    roughness?: number
    ca?: number
    characterization: Characterization[]
  }
  history: HistorySchema[]
  notes: NoteSchema[]
  issues: IssueSchema[]
}