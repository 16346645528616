import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Button } from 'react-bootstrap'
import MembraneNoteForm from '_containers/membranes/note-form'
import { NoteSchema } from '_stores/membranes/types'

interface MembraneNoteProps {
  note: NoteSchema
}

const MembraneNote: React.FunctionComponent<MembraneNoteProps> = ({
  note
}) => {
  const [isEditing, setIsEditing] = React.useState(false)
  return (
    <div className='note'>
      {
        isEditing ?
          <MembraneNoteForm 
            onCancel={ () => setIsEditing(false) }
            onSubmit={ () => setIsEditing(false) }
            note={ note }
          />
          :
          <blockquote>{ note.comment }</blockquote>
      }
      <div className='actions'>
        <Button
          size='sm'
          variant='link'
          onClick={ () => setIsEditing(true) }
        >
          <FontAwesomeIcon icon={ faPen } />
        </Button>
        <Button
          size='sm'
          variant='link'
        >
          <FontAwesomeIcon icon={ faTrash } />
        </Button>
      </div>
    </div>
  )
}

export default MembraneNote