const buildStoreKey = (storeName: string): string =>
  `__store_${storeName}`

export const saveStoreState = (storeName: string, state: { [key: string]: any }): void => {
  localStorage.setItem(buildStoreKey(storeName), JSON.stringify(state))
}

export const getStoreState = (storeName: string): any | null => {
  const stored = localStorage.getItem(buildStoreKey(storeName))
  if (!stored)
    return null
  try {
    return JSON.parse(stored)
  } catch (err) {
    return null
  }
}