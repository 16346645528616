import * as React from 'react'
import Title from '_components/title'
import { useCurrentArticle } from '_stores/articles/store'
import ArticleForm from './form'
import { useHistory, useLocation } from 'react-router-dom'
import PageApp from '_containers/app-pages/page-app'
import ApiItemWrapper from '_containers/api-item-wrapper'
import { useMedias } from '_stores/medias/store'

const ArticleItem: React.FunctionComponent = () => {
  const [currentArticle, actions] = useCurrentArticle()
  const [mediasState, mediasActions] = useMedias()
  const history = useHistory()
  const location = useLocation()
  const isReleases = location.pathname.match('releases')

  React.useEffect(() => {
    mediasActions.fetch()
  }, [])

  return (
    <PageApp>
      <ApiItemWrapper
        item={ currentArticle }
        actions={ actions }
      >
        <Title
          label={ currentArticle ? currentArticle.meta.title : isReleases ? 'New release' : 'New post' }
          button={ {
            label: 'Back to list',
            onClick: () => history.push(isReleases ? '/releases' : '/news')
          } }
        />
        <ArticleForm
          type={ isReleases ? 'release' : 'post' }
        />
      </ApiItemWrapper>
    </PageApp>
  )
}

export default ArticleItem