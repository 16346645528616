import * as React from 'react'
import routes, { getRoute } from '../routes'
import { Switch, Route, useHistory, useLocation, Redirect } from 'react-router-dom'

const Anonymous: React.FunctionComponent = () => {
  const history = useHistory()
  const location = useLocation()

  React.useEffect(() => {
    const currentRoute = getRoute(location.pathname)
    if (!currentRoute)
      return console.log('404') // TODO: 404 page
    if (currentRoute.logged && currentRoute.logged !== 'common')
      return history.push('/login')
  }, [])

  return (
    <Switch>
      {
        routes.filter(route => !route.logged || route.logged === 'common').map(route =>
          <Route
            key={ route.path }
            path={ route.path }
            component={ route.component }
            exact
          />
        )
      }
      <Route exact path='/'>
        <Redirect to='/login' />
      </Route>
    </Switch>
  )
}

export default Anonymous